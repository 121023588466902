import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {Thumbnail ,Grid,Col,Button,Row,Jumbotron} from 'react-bootstrap';
//import { Card, CardImg } from 'reactstrap';
// import {Thumbnail ,Grid,Col,Image,Button,Row} from 'react-bootstrap';
import '../Styles/home.css'
export default class TourPlace extends Component{
    render(){
        return(
            <div>
            <div className='header1'>
                <Grid>
                    <Jumbotron>
                        <h2>OUR DESTINATIONS</h2>                        
                    </Jumbotron>
                </Grid>
            </div>
          
             
            <Grid>
                <Row className= "show-grid text-center">
                
                    <Col xs={12} sm={4} className="place">
                    <Thumbnail className="thumbnail_border1" src="assets/img/places/Munnar.jpg" alt="242x200">
                        <h3>MUNNAR</h3>
                        <p>
                        <Link to="/munnar">
                    <Button bsStyle="primary">More</Button></Link>
                        </p>
                    </Thumbnail>                   
                    </Col>

                    <Col xs={12} sm={4} className="place">
                    <Thumbnail className="thumbnail_border1" src="assets/img/places/kanyakumari.jpg"  alt="242x200">
                        <h3>KANYAKUMARI</h3>                        
                        <p>
                        <Link to="/kanyakumari">
                        <Button bsStyle="primary">More</Button></Link>
                        </p>
                    </Thumbnail>                   
                    </Col>

                    <Col xs={12} sm={4} className="place">
                    <Thumbnail className="thumbnail_border1" src="assets/img/places/alleppey.jpg" alt="242x200">
                        <h3>ALLEPPEY</h3>
                        <p>
                        <Link to="/Alappuzha">
                        <Button bsStyle="primary">More</Button></Link>
                        </p>
                    </Thumbnail>                   
                    </Col>
                </Row>
                <Row className= "show-grid text-center">
                
                <Col xs={12} sm={4} className="place">
                <Thumbnail className="thumbnail_border1" src="assets/img/places/KOVALAM.jpg" alt="242x200">
                    <h3>TRIVANDRUM</h3>
                    <p>
                    <Link to="/kovalam">
                <Button bsStyle="primary">More</Button></Link>
                    </p>
                </Thumbnail>                   
                </Col>
                <Col xs={12} sm={4} className="place">
                <Thumbnail className="thumbnail_border1" src="assets/img/places/Jatayu.jpg" alt="242x200">
                    <h3>JATAYU ROCK</h3>
                    <p>
                    <Link to="/Jatayu">
                <Button bsStyle="primary">More</Button></Link>
                    </p>
                </Thumbnail>                   
                </Col>

                <Col xs={12} sm={4} className="place">
                <Thumbnail className="thumbnail_border1" src="assets/img/places/Kumarakom_1.jpg" alt="242x200">
                    <h3>KUMARAKOM</h3>
                    <p>
                    <Link to="/Kumarakom">
                <Button bsStyle="primary">More</Button></Link>
                    </p>
                </Thumbnail>                   
                </Col>
                </Row>

                <Row className= "show-grid text-center">
                
                    <Col xs={12} sm={4} className="place">
                    <Thumbnail className="thumbnail_border1" src="assets/img/places/thekkady.jpg" alt="242x200">
                        <h3>THEKKADY</h3>
                        <p>
                        <Link to="/Thekkady">
                    <Button bsStyle="primary">More</Button></Link>
                        </p>
                    </Thumbnail>                   
                    </Col>

                    <Col xs={12} sm={4} className="place">
                    <Thumbnail className="thumbnail_border1" src="assets/img/places/rameshwaram-temple.jpg"  alt="242x200">
                        <h3>RAMESWARAM</h3>                        
                        <p>
                        <Link to="/rameshwaram">
                        <Button bsStyle="primary">More</Button></Link>
                        </p>
                    </Thumbnail>                   
                    </Col>

                    <Col xs={12} sm={4} className="place">
                    <Thumbnail className="thumbnail_border1" src="assets/img/places/MADURAI.jpg" alt="242x200">
                        <h3>MADURAI</h3>
                        <p>
                        <Link to="/madurai">
                        <Button bsStyle="primary">More</Button></Link>
                        </p>
                    </Thumbnail>                   
                    </Col>

                    <Col xs={12} sm={4} className="place">
                    <Thumbnail className="thumbnail_border1" src="assets/img/places/soojipara.jpg" alt="242x200">
                        <h3>WAYANAD</h3>
                        <p>
                        <Link to="/wayanad">
                        <Button bsStyle="primary">More</Button></Link>
                        </p>
                    </Thumbnail>                   
                    </Col>

                    <Col xs={12} sm={4} className="place">
                    <Thumbnail className="thumbnail_border1" src="assets/img/places/cochin.jpg" alt="242x200">
                        <h3>COCHIN</h3>
                        <p>
                        <Link to="/cochin">
                        <Button bsStyle="primary">More</Button></Link>
                        </p>
                    </Thumbnail>                   
                    </Col>

                    <Col xs={12} sm={4} className="place">
                    <Thumbnail className="thumbnail_border1" src="assets/img/places/guruvayur.jpg" alt="242x200">
                        <h3>GURUVAYUR</h3>
                        <p>
                        <Link to="/guruvayur">
                        <Button bsStyle="primary">More</Button></Link>
                        </p>
                    </Thumbnail>                   
                    </Col>
                </Row>                
            </Grid>
            </div>
        )}}