import React, { Component } from 'react';
// import {Link} from 'react-router-dom';
import {Grid,Image} from 'react-bootstrap';
import { Card, CardImg, CardBody, CardTitle, CardText } from 'reactstrap';
//import Card from 'react-bootstrap/Card';
// import {Grid,Col,Image,Button, Row} from 'react-bootstrap';
import '../Styles/about.css';
import '../Styles/home.css';

export default class Home extends Component{
  render(){
    return(
      <div>                
        <div className="content-wrapper">    
          <Image src="/assets/img/adventure1.jpg" />
          <h2 className="heading">2N Munnar - 1N Thekkady - 1N Alappuzha - 1N Cochin </h2>                    
        </div>
        <Grid>                    
          <Card className='my-2'>
            <CardBody style={{marginLeft:'10px', marginRight:'10px',paddingTop:'10px', paddingBottom:'10px'}}>
              <CardTitle tag="h2" style={{backgroundImage: '/public/assets/img/alleppey2.jpg'}}>Itinerary</CardTitle>
              <CardTitle tag="h3">Day 01</CardTitle>
              <CardTitle tag="h4">Cochin- Munnar</CardTitle>
              <CardText style={{fontSize:'16px'}}>
                On arrival at Cochin Airport or Railway station our representative will meet and drive you to Munnar. 150kms from Cochin.
              </CardText>
              <CardImg 
                alt="Card image cap"
                src="/assets/img/Munnar1.jpg"
                style={{
                    height: "100%"
                }}
                top
                width="100%" rounded/>
              <CardText style={{fontSize:'16px'}}>
                The Idyllic tourist destination in God’s own country. <strong>Munnar</strong> is a nature lovers paradise with endless Tea gardens lakes on western ghat hills , basically it is a tea town. After breakfast proceed for the sightseeing of Munnar covering Mattupetty Dam, Echo Point, Kundale Lake, Blossom Garden.In Mattupetty you can enjoy Elephant Safari, Horse Riding, Speed Boat. (own cost).In Kundala Dam you can enjoy Pedal Boating. Photo stops at Tea Gardens & Rose Gardens.
                Overnight stay at Hotel/Resort.
              </CardText>                        
            </CardBody>                        
          </Card>

          <Card className='my-2'>                            
            <CardBody style={{marginLeft:'10px', marginRight:'10px',paddingTop:'10px', paddingBottom:'10px'}}>
              <CardTitle tag="h3">Day 02</CardTitle>
              <CardTitle tag="h4">Munnar</CardTitle>
              <CardImg 
                alt="Card image cap"
                src="/assets/img/Munnar2.jpg"
                style={{
                    height: "100%"
                }}
                top
                width="100%" rounded/>
              <CardText style={{fontSize:'16px'}}>
                After breakfast proceed to morning sightseeing Rajamalai Hills offering a spectacular view, Eravikulam Wildlife sanctuary, home of Nilgiri Thar( very rare mountain goat), considering the ecological faunal, floral, geo-morphological and zoological significant & Tea Plantation.Later Visit Tea Museum & Shopping at Munnar Town. Evening visit Kathakali Show (own cost).
              </CardText>
              <CardText style={{fontSize:'16px'}}>
                Overnight stay at Munnar Hotel/Resort.
              </CardText>
            </CardBody>                        
          </Card>

          <Card className='my-2'>
            <CardBody style={{marginLeft:'10px', marginRight:'10px',paddingTop:'10px', paddingBottom:'10px'}}>
              <CardTitle tag="h3">Day 03</CardTitle>
              <CardTitle tag="h4">Munnar - Thekkady</CardTitle>
              <CardImg 
                alt="Card image cap"
                src="/assets/img/thekkady.jpg"
                style={{
                    height: "100%"
                }}
                top
                width="100%" rounded/>
              <CardText style={{fontSize:'16px'}}>
                After breakfast proceed to Thekkady. 900kms from Munnar.
              </CardText>
              <CardText style={{fontSize:'16px'}}>
                <strong>Thekkady</strong>  which offers diverse attractions like Periyar Wildlife Sanctuary, Hill Station and Spice Plantation. Here you can go for a Wildlife cruise to view a large variety of wildlife like Wild Elephants, Nilgiri Langur, Sambar Deer, Wild Bison and quite a lot of unique birds can also be spotted. You can recreate yourself by indulging in optional (chargeable basis) activities like Spice Plantation Tour, Bamboo Rafting, Trekking and Elephant ride. 
                Overnight stay at the Hotel/Resort.
              </CardText>
            </CardBody>                        
          </Card>

          <Card className='my-2'>
            <CardBody style={{marginLeft:'10px', marginRight:'10px',paddingTop:'10px', paddingBottom:'10px'}}>
              <CardTitle tag="h3">Day 04</CardTitle>
              <CardTitle tag="h4">Thekkady - Alappuzha</CardTitle>
              <CardImg 
                alt="Card image cap"
                src="/public/assets/img/alleppey2.jpg"
                style={{
                    height: "100%"
                }}
                top
                width="100%" rounded/>
              <CardText style={{fontSize:'16px'}}>
                After breakfast proceed to Alleppey. 140kms from Thekkady..
              </CardText>
              <CardText style={{fontSize:'16px'}}>
                <strong>Alappuzha</strong> is the Venice of the East; It is famous for its boat races, backwater
                holidays, beaches, marine products and coir industry. Alappuzha beach is a popular
                picnic spot. The pier, which extends into the sea here, is over 137 years old.

                Entertainment facilities at the Vijaya Beach Park add to the attraction of the beach.
                There is also an old lighthouse nearby which is greatly fascinating to visitors.
                Overnight stay at Alleppey.
              </CardText>
              <CardText style={{fontSize:'16px'}}>
                (Option 2: <strong>House Boat</strong> : This 21 hrs. Kerala houseboat package in Alleppey is designed for those who want to experience Alleppey backwaters in a houseboat. It is necessary to have proper Guidance and information about the Kerala Tour Packages before visiting this places.
                <br/>Experience <strong>Alleppey backwaters</strong>, one of the must see destinations during a life time, in a houseboat is an experience beyond imagination. The main attraction of Alleppey is criss crossed canals, lagoons, villages on the banks of these canals, paddy fields etc – all these makes Alleppey a much sought after destination among tourists. To see these Alleppey backwaters you need to hire any means of water transport. This program is designed to experience Alleppey backwaters with overnight stay in a private houseboat.
                <br/>During the trip in houseboat for 21 hrs. you will be provided fresh lemon as welcome drink, authentic Kerala veg and non-veg along with local rice and many vegetable dishes. Welcome drink, lunch, evening refreshments, dinner and next day breakfast are all included during your stay in houseboat and all these are prepared in the houseboat itself by exclusive chef.  According to category of the houseboat you booked from Kerala Houseboat package or Alleppey Houseboat Package, the menu may differ.
                <br/>
                <ul>
                  <li>Boarding and Deboarding place – Alleppey Punnamada jetty / Pallathuruthy Jetty</li>
                  <li>Check in  time – 12:30 hrs.</li>
                  <li>Check Out time – 09:00 hrs. Next day.</li>
                </ul>
              </CardText>
            </CardBody>                       
          </Card>   
                    
          <Card className='my-2'>                            
            <CardBody style={{marginLeft:'10px', marginRight:'10px',paddingTop:'10px', paddingBottom:'10px'}}>
              <CardTitle tag="h3">Day 05</CardTitle>
              <CardTitle tag="h4">Alappuzha - Cochin</CardTitle>
              <CardImg 
                alt="Card image cap"
                src="/assets/img/Cochin1.jpg"
                style={{
                    height: "100%"
                }}
                top
                width="100%" rounded/>
              <CardText style={{fontSize:'16px'}}>
              After breakfast proceed to <strong>Cochin</strong>. 60kms from Alleppey.
              </CardText>
              <CardText style={{fontSize:'16px'}}>
                Afternoon proceed for a half day city sightseeing tour of Cochin visiting <strong>Chinese
                Fishing Nets, St. Francis Church, Santa Cruz Basilica, Jewish Synagogue, and
                Mattancherry Palace/Dutch Palace.Shopping at Maraine Drive.</strong>
              </CardText>
              <CardText style={{fontSize:'16px'}}>
                Overnight stay at Cochin Hotel/Resort.        
              </CardText>
            </CardBody>                        
          </Card> 
                    
          <Card className='my-2'>                            
            <CardBody style={{marginLeft:'10px', marginRight:'10px',paddingTop:'10px', paddingBottom:'10px'}}>
              <CardTitle tag="h3">Day 06</CardTitle>
              <CardTitle tag="h4">Cochin Drop</CardTitle>
              <CardImg 
                alt="Card image cap"
                src="/assets/img/tourism.jpg"
                style={{
                    height: "100%"
                }}
                top
                width="100%" rounded/>
              <CardText style={{fontSize:'16px'}}>
                After breakfast proceed to Drop at Cochin Airport or Railway station.
                If possible Sightseeing.
              </CardText>
              <CardText style={{fontSize:'16px'}}>
                Drive back to your hometown with the sweet memories of Kerala trip with <strong>Kairaly Holidays</strong>.
              </CardText>
            </CardBody>                        
          </Card>                 
                                
          <iframe width="850" height="508" src="https://www.youtube.com/embed/SSaDk1PxJkg" title="Alappuzha Aerial View | Aerial Shots of Kerala Destinations" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </Grid>
      </div>
    )
  }
}