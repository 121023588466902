import React, { Component } from 'react';
import { BrowserRouter as Router , Route } from "react-router-dom";
import  Navigation from "./components/shared/Navigation";
import  Footer from "./components/shared/Footer";

import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import Destinations from './components/Destinations';
import Varkala from './components/Varkala';
import Kovalam from './components/Kovalam';
import Munnar from './components/Munnar';
import Kanyakumari from './components/Kanyakumari';
import Alappuzha from './components/Alappuzha';
import Jatayu from './components/Jatayu';
import Kumarakom from './components/Kumarakom';
import Thekkady from './components/Thekkady';
import Rameshwaram from './components/Rameshwaram';
import Madurai from './components/Madurai';
import Wayanad from './components/Wayanad';
import Cochin from './components/Cochin';
import Guruvayur from './components/Guruvayur';

import Contact from './components/Contact';
import package1 from './components/Package1';
import package2 from './components/Package2';
import package3 from './components/Package3';
import Packages from './components/Packages';
import './App.css';

class App extends Component {
  render() {
    return (
      <Router>  
        
        <div>
        <Navigation/>
        <Route exact path="/" component={Home} />
          <Route path="/package1" component={package1} />
          <Route path="/package2" component={package2} />
          <Route path="/package3" component={package3} />
          <Route path="/Packages" component ={Packages} />
        <Route path="/about" component={About} />
        <Route path="/services" component={Services} />
        <Route path="/destinations" component={Destinations} />        
        <Route path="/contact" component={Contact} />
        <Route path="/Varkala" component={Varkala} />
        <Route path="/Kovalam" component={Kovalam} />
        <Route path="/Munnar" component={Munnar} />
        <Route path="/Kanyakumari" component={Kanyakumari} />
        <Route path="/Alappuzha" component={Alappuzha} />
        <Route path="/Jatayu" component={Jatayu} />
        <Route path="/Kumarakom" component={Kumarakom} />
        <Route path="/Thekkady" component={Thekkady} />
        <Route path="/Rameshwaram" component={Rameshwaram} />
        <Route path="/Madurai" component={Madurai} />
        <Route path="/Wayanad" component={Wayanad} />
        <Route path="/Cochin" component={Cochin} />
        <Route path="/Guruvayur" component={Guruvayur} />
        <Footer/>
        </div>
      </Router>
    );
  }
}

export default App;
