import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {Jumbotron,Grid,Container, Image} from 'react-bootstrap';
// import {Jumbotron,Grid,Col,Image,Button,Row} from 'react-bootstrap';
import TourPlace from "./TourPlace";
import '../Styles/home.css'
export default class Home extends Component{
  render(){
    return(
      <div>
        <div className="header">               
          <Grid>
            <Jumbotron>
              <h2>Kerala Holidays Redefined</h2>
              <h3>Explore This Holiday With Kairaly</h3>
              <Link to="/services">
              <div class="btn btn--huge">
  <div class="btn--huge__text">
    <div>
      Explore More
      <span>Explore More</span>
    </div>
  </div>
</div></Link>
            </Jumbotron>
          </Grid>
        </div>                 
        <Grid>             
          <div className="banner text-center">
            <h2 >The best agency you can choose</h2>
            <p>We are committed to offering travel services of the highest quality, combining our energy and enthusiasm with years of experience.</p>
          </div>
                
          <TourPlace/>               
        </Grid>        
        <div className="content-wrapper">    
          <Image src="/assets/img/newyear.jpg" />
        </div>      
        <div className="banner text-center">
          <h2 >Our Successfull Groups</h2>
          <p>Group From Nazik, Maharashtra</p>          
          <div className="content-wrapper">    
            <Image src="/assets/img/group1.jpg" style={{ width: '50%', display: 'block', margin: '0 auto' }}/>
          </div>
          <p>Temple tour -- Kanyakumari - Rameswaram - Madurai - Trivandrum --</p>          
          <div className="content-wrapper">    
            <Image src="/assets/img/group2.jpg" style={{ width: '50%', display: 'block', margin: '0 auto' }}/>
          </div>
          <p>Successful group tour
135 pax
Thanks to our Agents From Maharashtra, Red Sparrow Bus,  Sealord Hotels, Bluhaze resort, Woodland Prime castle, Pagoda Resort</p>          
          <div className="content-wrapper">    
            <Image src="/assets/img/group3.jpg" style={{ width: '50%', display: 'block', margin: '0 auto' }}/>
          </div>
          <p>Successful  Kerala Trip 
Thanks for our Agent , Hotels and our pilots</p>          
          <div className="content-wrapper">    
            <Image src="/assets/img/group4.jpg" style={{ width: '50%', display: 'block', margin: '0 auto' }}/>
          </div>
          <p>Temple tours started on 11 May
Trivandrum - Kanyakumari -Madurai- Rameswaram - trichi - Thanjavur - Tirupati
Thanks for our Agent From Mumbai</p>          
          <div className="content-wrapper">    
            <Image src="/assets/img/group5.jpg" style={{ width: '50%', display: 'block', margin: '0 auto' }}/>
          </div>
        </div>
      </div>
    )
  }
}