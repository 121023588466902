import React, { Component } from 'react';
// import {Link} from 'react-router-dom';
import {Grid,Col,Image} from 'react-bootstrap';
// import {Grid,Col,Image,Button, Row} from 'react-bootstrap';
import '../Styles/about.css';

export default class About extends Component{
  render(){
    return(
      <div>
        <div className="content-wrapper"> 
        <Image src="/assets/img/about.jpg" />
            {/* <h2 className="heading">Welcome to Kairaly Hotels </h2> */}
        </div>
        <Grid>
          <Col xs={12} sm={8} smOffset={2}    >
          <Image src="/assets/img/travel.jpg" className="about-pc" rounded />
            <h3>Traveling. Tours. Adventure.</h3>
           
            <p>We are one of the famous tour operators in and arround Kerala providing built in or user customizable tour packages which include site seeing, trecking, House boat cruise, art and cultural events and muchmore that makes you enjoy your trip. Trust is one of the important feature that attract people to us and this bond keeps the goodwill of our organization.</p>
            
            <p>Nurtured in natures lap, Our scenarios are designed with our guests in mind that human mitigations find absolute resorts here. Like colors which are eye's icon, and fragrance are that of nostrils, your presence in this ambiance can make yourself exposed to an elite catastrophic adobe of globe.</p>
            <p>It will be explicitly true to say that the facilitations that we can offer here are replete with all sorts of comforts and serenity. If God can be known by the name perfection then, it will be proud to say that for those who are in search of lands with impectuous beauty, we are perfectus. </p>

            <p>We are spread all over Kerala & Tamilnadu with 1000+ Hotels offering standardized stay experiences at an unmatched price. We take pleasure to introduce ourselves as a leading and highly reputed inbound tour management organization which is known gor its quality of services. We are specialized in hotel reservation, house boat reservations, all types of vehicles & tailor made tours according to guest needs. </p>
            <p>We have a network of Kerala & Tamilnadu Hotels, House boat cruises at Alleppey / Kumarakom & Taxi services from all Kerala Tour Destinations, range of all vehicles Such as Innova A/c, Tavera A/c, Xylo A/c, Swift Dezire, Logan/Indigo/Verito & Indica, For group packages we have 49 seat, 36 seat, 27 seat deluxe buses & Travelers 26, 17, 14 & 12 seat Etc....... </p>
            <p>We do offer vehicles with well experienced family friendly Hindi / English speaking Driver who can guide you well throughout the South Indian Trip. Also we are arranging Kerala Packages (Holiday Packages (Domestic & International), Pilgrim Packages, Family and student Group tours, Hotel Reservations, House Boat Reservations, All Types of Vehicles, Air Ticketing, Bus Ticketing, House Keeping Services Etc..) </p>
          </Col>
        </Grid>
      </div>
    )
  }
}