import React, { Component } from 'react';
import { Button, Form, FormControl } from 'react-bootstrap';
import emailjs from 'emailjs-com';
import '../Styles/formcontact.css';


export default class FormContact extends Component{  
  constructor(){
    super()
    this.state={
      name: '',
      email: '',
      message: '',
      mobile: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
  }
    
    // getValidationState () {
    //     const length = this.state.value.length;
    //     if (length > 10) return 'success';
    //     else if (length > 5) return 'warning';
    //     else if (length > 0) return 'error';
    // }

  handleChange (event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  resetForm() {
    this.setState({
      name: '',
      email: '',
      message: '',
      mobile: '',
    });
  }

  sendEmail = (e) => {
    e.preventDefault();
      
    const { name, email, message, mobile } = this.state;

    if (!name || !email || !message || !mobile) {
      return;
    }

    const templateParams = {
      from_name: name,
      to_name: "Kairaly Holidays",
      from_email: email,
      mobile,
      message,
    };

    emailjs.send('service_vzrqhd5', 'template_7lat3ja', templateParams, '8wLvZj8pWrPXonpeC')
      .then((result) => {
        console.log("SUCCESS!", result.text);
        alert("SUCCESS");
        this.resetForm();
      }, (error) => {
        console.log("FAILED...", error.text);
        alert("FAILED...",error);
    });
  }
    
  render () {
    return (
      <Form onSubmit={this.sendEmail}>
        <FormControl 
          name="name"
          type="text"
          label="Your Name"
          placeholder="Your Name"
          value={this.state.name}
          onChange={this.handleChange}
        />
        <FormControl
            name="email"
            type="email"
            label="Email address"
            placeholder="Email"
            value={this.state.email}
            onChange={this.handleChange}
          />
          <FormControl
            name="mobile"
            type="number"
            label="Mobile Number"
            placeholder="mobile"
            value={this.state.mobile}
            onChange={this.handleChange}
          />
          <FormControl 
            name="message"
            as="textarea"
            placeholder="Message"
            value={this.state.message}
            onChange={this.handleChange}
          />
        <Button bsStyle="primary" type="submit">Send Message</Button>
      </Form>
    );
  }
}