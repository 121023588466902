import React, { Component } from 'react';
// import {Link} from 'react-router-dom';
import {Grid,Image} from 'react-bootstrap';

import { Card, CardImg, CardBody, CardTitle, CardText, CardImgOverlay } from 'reactstrap';
//import Card from 'react-bootstrap/Card';
// import {Grid,Col,Image,Button, Row} from 'react-bootstrap';
import '../Styles/about.css';
import '../Styles/home.css';

export default class Home extends Component{
    render(){
        return(
            <div>                
                <div className="content-wrapper">    
                    <Image src="/assets/img/collage.jpg" />
                    <h2 className="heading">1N Kovalam - 1N Kanyakumari - 1N Rameswaram - 1N Madurai - 2N Munnar -
                    1N Thekkady - 1N Alleppey - 1N Cochin</h2>
                    
                </div>
                <Grid>
                <Card className='my-2'>
                        
                <CardBody style={{marginLeft:'10px', marginRight:'10px',paddingTop:'10px', paddingBottom:'10px'}}>
                    <CardTitle tag="h2">Itinerary</CardTitle>
                    <CardTitle tag="h3">Day 01</CardTitle>
                    <CardTitle tag="h4">Trivandrum - Kovalam</CardTitle>
                    <CardText style={{fontSize:'16px'}}>
                    On arrival at Trivandrum Airport or Railway station our representative will meet and
                    drive you to <strong>Kovalam</strong>. 15kms from Trivandrum.
                </CardText>
                    <CardImg 
                    alt="Card image cap"
                    src="/assets/img/tvpm.jpg"
                    style={{
                        height: "100%"
                    }}
                    top
                    width="100%" rounded/>
                    
                <CardText style={{fontSize:'16px'}}>
                Visit <strong>Sree Padmanabha Swami Temple</strong>, Kuthiramalika Palace museum, The Napier
museum, Sree Chithra art gallery, zoological park, science and technology museum,
Kanakakunnu Palace, planetarium, <strong>Kovalam</strong> is known as the <strong>“Paradise of the
South”</strong>. Visit the crescent shaped beaches of Kovalam i.e. Samudra Beach, Hawa
Beach &amp; Lighthouse Beach. With high rock promontory and a calm bay of blue
waters, this beach paradise creates a unique aquarelle on moonlight nights. 
Overnight stay at Kovalam / Trivandrum Hotel/Resort.
                </CardText>
                </CardBody>                        
            </Card>
            <Card className='my-2'>
                        
                <CardBody style={{marginLeft:'10px', marginRight:'10px',paddingTop:'10px', paddingBottom:'10px'}}>
                    <CardTitle tag="h3">Day 02</CardTitle>
                    <CardTitle tag="h4">Kovalam - Kanyakumari</CardTitle>
                    <CardText style={{fontSize:'16px'}}>
                    After breakfast proceed to <strong>Kanyakumari</strong>. 95 kms from Kovalam
                </CardText>
                    <CardImg 
                    alt="Card image cap"
                    src="/assets/img/Kanyakumari2.jpg"
                    style={{
                        height: "100%"
                    }}
                    top
                    width="100%" rounded/>
                    
                <CardText style={{fontSize:'16px'}}>
                <strong>Kanyakumari</strong> is a coastal town in the state of Tamil Nadu on India's southern tip.
Jutting into the Laccadive Sea, the town was known as Cape Comorin during British
rule and is popular for watching sunrise and sunset over the ocean. Visit
<strong>Padmanabhapuram Palace, Thiruvalluvar Statue, Vivekananda Rock Memorial,
Bhagavathy Amman Temple, Gandhi Memorial, Bharat Mata Temple, Kanyakumari
Beach, Sunset View Point.</strong>
                </CardText>
                <CardText style={{fontSize:'16px'}}>
                Overnight stay at Kanyakumari hotel.
                </CardText>
                </CardBody>                        
            </Card>
            <Card className='my-2'>
                        
            <CardBody style={{marginLeft:'10px', marginRight:'10px',paddingTop:'10px', paddingBottom:'10px'}}>
                
                <CardTitle tag="h3">Day 03</CardTitle>
                <CardTitle tag="h4">Kanyakumari - Rameswaram</CardTitle>
                <CardText style={{fontSize:'16px'}}>
                After breakfast proceed to <strong>Rameswaram</strong>. 320 kms from Kanyakumari.
            </CardText>
                <CardImg 
                alt="Card image cap"
                src="/assets/img/rameswaram1.jpg"
                style={{
                    height: "100%"
                }}
                top
                width="100%" rounded/>
                
            <CardText style={{fontSize:'16px'}}>
            Rameswaram Sightseeing – Ramanathaswamy Temple, Agnitheertham, Dha
nushkodi (Jeep cost extra), Former President Shri. Dr. Abdul Kalam’s House,
Pamban Bridge.
</CardText>
<CardText style={{fontSize:'16px'}}>
Overnight Stay at Rameswaram Hotel .
            </CardText>
            </CardBody>                        
        </Card>
        <Card className='my-2'>
                        
        <CardBody style={{marginLeft:'10px', marginRight:'10px',paddingTop:'10px', paddingBottom:'10px'}}>
            
            <CardTitle tag="h3">Day 04</CardTitle>
            <CardTitle tag="h4">Rameswaram - Madurai</CardTitle>
            <CardText style={{fontSize:'16px'}}>
            After breakfast proceed to <strong>Madurai</strong>. 160 kms from Rameswaram
        </CardText>
            <CardImg 
            alt="Card image cap"
            src="/assets/img/madurai1.jpg"
            style={{
                height: "100%"
            }}
            top
            width="100%" rounded/>
            
        <CardText style={{fontSize:'16px'}}>
        Madurai Sightseeing –  <strong>Meenakshi Amman Temple, Thirumalai Nayak Palace, 
Thirupparankundram, Gandhi Museum</strong>
</CardText>
<CardText style={{fontSize:'16px'}}>
Overnight stay at Madurai Hotel.        </CardText>
        </CardBody>                        
    </Card>                         
    
    <Card className='my-2'>
                    
    <CardBody style={{marginLeft:'10px', marginRight:'10px',paddingTop:'10px', paddingBottom:'10px'}}>
        
        <CardTitle tag="h3">Day 05</CardTitle>
        <CardTitle tag="h4">Madurai - Munnar</CardTitle>
        <CardText style={{fontSize:'16px'}}>
        After breakfast proceed to <strong>Munnar</strong>. 160kms from Madurai.
    </CardText>
        <CardImg 
        alt="Card image cap"
        src="/assets/img/munnar2.jpg"
        style={{
            height: "100%"
        }}
        top
        width="100%" rounded/>
        
    <CardText style={{fontSize:'16px'}}>
    Enroute Usilampatti, Theni, Bodinayakanur, Devikulam Spectacular Views reach 
   <strong>Munnar</strong> .Check in Resort and then Visit Hydal Park (Blossom Park).
</CardText>
<CardText style={{fontSize:'16px'}}>
Overnight stay at the Hotel/Resort.        </CardText>
    </CardBody>                        
</Card>  
<Card className='my-2'>
                    
<CardBody style={{marginLeft:'10px', marginRight:'10px',paddingTop:'10px', paddingBottom:'10px'}}>
    
    <CardTitle tag="h3">Day 06</CardTitle>
    <CardTitle tag="h4">Munnar</CardTitle>
    <CardText style={{fontSize:'16px'}}>
    After breakfast proceed to Munnar Sightseeing.
</CardText>
    <CardImg 
    alt="Card image cap"
    src="/assets/img/munnar1.jpg"
    style={{
        height: "100%"
    }}
    top
    width="100%" rounded/>
    
<CardText style={{fontSize:'16px'}}>
<strong>Munnar</strong> is a nature <strong>lovers paradise</strong>
with endless teagardens lakes on western ghat hills , basically it is a tea town. After
breakfast proceed for the sightseeing of Munnar covering <strong>Mattupetty Dam, Echo
Point, Kundale Lake.In Mattupetty you can enjoy Elephant Safari, Horse Riding,
Speed Boat</strong>. (own cost).In Kundala Dam you can enjoy Pedal Boating.Photo stops
at Tea Gardens &amp; Rose Gardens.
</CardText>
<CardText style={{fontSize:'16px'}}>
Overnight stay at the Hotel/Resort.        </CardText>
</CardBody>                        
</Card>         
   
<Card className='my-2'>
                    
<CardBody style={{marginLeft:'10px', marginRight:'10px',paddingTop:'10px', paddingBottom:'10px'}}>
    
    <CardTitle tag="h3">Day 07</CardTitle>
    <CardTitle tag="h4">Munnar - Thekkady</CardTitle>
    <CardText style={{fontSize:'16px'}}>
    After breakfast proceed to Thekkady. 90kms from Munnar.
</CardText>
    <CardImg 
    alt="Card image cap"
    src="/assets/img/thekkady.jpg"
    style={{
        height: "100%"
    }}
    top
    width="100%" rounded/>
    
<CardText style={{fontSize:'16px'}}>
<strong>Thekkady</strong> which offers diverse attractions like Periyar Wildlife Sanctuary, Hill Station
and Spice Plantation. Here you can go for a Wildlife cruise to view a large variety of
wildlife like Wild Elephants, Nilgiri Langur, Sambar Deer, Wild Bison and quite a lot
of unique birds can also be spotted. You can recreate yourself by indulging in
optional (chargeable basis) activities like <strong>Spice Plantation Tour, Bamboo Rafting,
Trekking and Elephant ride.</strong>
</CardText>
<CardText style={{fontSize:'16px'}}>
Overnight stay at the Hotel/Resort.        </CardText>
</CardBody>                        
</Card>  
<Card className='my-2'>
                    
<CardBody style={{marginLeft:'10px', marginRight:'10px',paddingTop:'10px', paddingBottom:'10px'}}>
    
    <CardTitle tag="h3">Day 08</CardTitle>
    <CardTitle tag="h4">Thekkady - Alleppey</CardTitle>
    <CardText style={{fontSize:'16px'}}>
    After breakfast proceed to Alleppey. 140kms from Thekkady.
</CardText>
    <CardImg 
    alt="Card image cap"
    src="/public/assets/img/alleppey2.jpg"
    style={{
        height: "100%"
    }}
    top
    width="100%" rounded/>
    
<CardText style={{fontSize:'16px'}}>
<strong>Alappuzha</strong> is the Venice of the East; It is famous for its boat races, backwater
holidays, beaches, marine products and coir industry. Alappuzha beach is a popular
picnic spot. The pier, which extends into the sea here, is over 137 years old.

Entertainment facilities at the Vijaya Beach Park add to the attraction of the beach.
There is also an old lighthouse nearby which is greatly fascinating to visitors.
Overnight stay at Alleppey.
</CardText>
<CardText style={{fontSize:'16px'}}>
                        (Option 2: <strong>House Boat</strong> : This 21 hrs. Kerala houseboat package in Alleppey is designed for those who want to experience Alleppey backwaters in a houseboat. It is necessary to have proper Guidance and information about the Kerala Tour Packages before visiting this places.
                        <br/>Experience <strong>Alleppey backwaters</strong>, one of the must see destinations during a life time, in a houseboat is an experience beyond imagination. The main attraction of Alleppey is criss crossed canals, lagoons, villages on the banks of these canals, paddy fields etc – all these makes Alleppey a much sought after destination among tourists. To see these Alleppey backwaters you need to hire any means of water transport. This program is designed to experience Alleppey backwaters with overnight stay in a private houseboat.
                        <br/>During the trip in houseboat for 21 hrs. you will be provided fresh lemon as welcome drink, authentic Kerala veg and non-veg along with local rice and many vegetable dishes. Welcome drink, lunch, evening refreshments, dinner and next day breakfast are all included during your stay in houseboat and all these are prepared in the houseboat itself by exclusive chef.  According to category of the houseboat you booked from Kerala Houseboat package or Alleppey Houseboat Package, the menu may differ.
                        <br/>
                        <ul>
                            <li>Boarding and Deboarding place – Alleppey Punnamada jetty / Pallathuruthy Jetty</li>
                            <li>Check in  time – 12:30 hrs.</li>
                            <li>Check Out time – 09:00 hrs. Next day.</li>
                        </ul>
                        </CardText>

</CardBody>                        
</Card>  
<Card className='my-2'>
                    
<CardBody style={{marginLeft:'10px', marginRight:'10px',paddingTop:'10px', paddingBottom:'10px'}}>
    
    <CardTitle tag="h3">Day 09</CardTitle>
    <CardTitle tag="h4">Alleppey - Cochin</CardTitle>
    <CardText style={{fontSize:'16px'}}>
    After breakfast proceed to <strong>Cochin</strong>. 60kms from Alleppey.
</CardText>
    <CardImg 
    alt="Card image cap"
    src="/assets/img/Cochin1.jpg"
    style={{
        height: "100%"
    }}
    top
    width="100%" rounded/>
    
<CardText style={{fontSize:'16px'}}>
Afternoon proceed for a half day city sightseeing tour of Cochin visiting <strong>Chinese
Fishing Nets, St. Francis Church, Santa Cruz Basilica, Jewish Synagogue, and
Mattancherry Palace/Dutch Palace.Shopping at Maraine Drive.</strong>
</CardText>
<CardText style={{fontSize:'16px'}}>
Overnight stay at Cochin Hotel/Resort.        </CardText>
</CardBody>                        
</Card>
<Card className='my-2'>
                    
<CardBody style={{marginLeft:'10px', marginRight:'10px',paddingTop:'10px', paddingBottom:'10px'}}>
    
    <CardTitle tag="h3">Day 10</CardTitle>
    <CardTitle tag="h4">Cochin Drop</CardTitle>
    <CardText style={{fontSize:'16px'}}>
    After breakfast proceed to Drop at Cochin Airport or Railway station.
</CardText>
    <CardImg 
    alt="Card image cap"
    src="/assets/img/Kerala.jpg"
    style={{
        height: "100%"
    }}
    top
    width="100%" rounded/>
    
<CardText style={{fontSize:'16px'}}>
If possible Sightseeing.
Drive back to your hometown with the sweet memories of Tamilnadu & Kerala trip with
<strong>Kairaly Holidays</strong>.
</CardText>
</CardBody>                        
</Card>
<Card>
                    <CardImg 
                    alt="Card image cap"
                    src="/assets/img/kathakali.png"
                    style={{
                        height: "25%"
                        
                    }}
                    width="25%"/>
                    <CardImgOverlay>
                    <CardTitle tag="h3">Key Notes:</CardTitle>
                    <CardText style={{fontSize:'16px'}}>
                        
                        <ul>
                            <li><strong>If Diesel, Toll Tax or State Tax is Increased the given rate is not valid.</strong></li>
                            <li><strong>Vehicle will not travel on Unmetalled &amp; narrow roads.</strong></li>
                            <li><strong>Non Ac Rooms will be provided in Hill Station</strong></li>
                            <li><strong>Vehicle Ac will not work in ghat roads and in hill station</strong></li>
                            <li><strong>Deluxe Houseboat Ac Time will be 9.00 PM - 6.00 AM</strong></li>
                            <li><strong>Premium Houseboat Ac will work all time</strong></li>
                        </ul>
                    </CardText>
                    </CardImgOverlay>      

</Card>   

                  
                </Grid>
            
             



            </div>
        )
    }
}