import React, { Component } from 'react';
// import {Link} from 'react-router-dom';
import {Grid,Image} from 'react-bootstrap';

import { Card, CardImg, CardBody, CardTitle, CardText } from 'reactstrap';
//import Card from 'react-bootstrap/Card';
// import {Grid,Col,Image,Button, Row} from 'react-bootstrap';
import '../Styles/about.css';
import '../Styles/home.css';

export default class Home extends Component{
  render(){
    return(
      <div>                
        <div className="content-wrapper">    
          <Image src="/assets/img/poorna.jpg" />
            <h2 className="heading">Cochin – Munnar – Thekkady – Madurai – Rameswaram - Kanyakumari - Trivandrum -
            Alleppey – Cochin</h2>                    
        </div>
        <Grid>
          <Card className='my-2'>
            <CardBody style={{marginLeft:'10px', marginRight:'10px',paddingTop:'10px', paddingBottom:'10px'}}>
              <CardTitle tag="h2">Itinerary</CardTitle>
              <CardTitle tag="h3">Day 01</CardTitle>
              <CardTitle tag="h4">Cochin -Munnar</CardTitle>
              <CardText style={{fontSize:'16px'}}>
                Early Moring 4.10 Pick up from Ernakulum Railway station, our representative
                will meet and drive you to <strong>Munnar</strong>. 145 kms from Cochin. The Idyllic tourist destination
                in God’s own country. 
              </CardText>
              <CardImg 
                alt="Card image cap"
                src="/assets/img/munnar2.jpg"
                style={{
                  height: "100%"
                }}
                top
                width="100%" rounded/>
              <CardText style={{fontSize:'16px'}}>
                On the way you can also watch the adorable waterfalls in
                <strong>Cheeyappara and Vallara</strong>, colorful flowers . Explore rest of the day along with the
                nature. Evening visit Blossom Garden. Munnar Town/Kathakali Show(OwnCost)
                Overnight stay at the Hotel/Resort.
              </CardText>
            </CardBody>                        
          </Card>

          <Card className='my-2'>
            <CardBody style={{marginLeft:'10px', marginRight:'10px',paddingTop:'10px', paddingBottom:'10px'}}>
              <CardTitle tag="h3">Day 02</CardTitle>
              <CardTitle tag="h4">Munnar</CardTitle>
              <CardText style={{fontSize:'16px'}}>
                After breakfast proceed for a morning sightseeing trip of Munnar visiting the
                <strong>Eravikulam National Park</strong> where you can see the very rare mountain goats natively
                called the Nilgiri Tahr.
              </CardText>
              <CardImg 
                alt="Card image cap"
                src="/assets/img/munnar1.jpg"
                style={{
                    height: "100%"
                }}
                top
                width="100%" rounded/>
              <CardText style={{fontSize:'16px'}}>
                Visit <strong>Tea Museum and Mattupetty Lake</strong> &amp; Dam &amp; Kundala
                Lake. Enroute visit Echo Point, View Point &amp; green carpet of Tea Gardens.
              </CardText>
              <CardText style={{fontSize:'16px'}}>
                Overnight stay at Munnar Hotel/Resort.
              </CardText>
            </CardBody>                        
          </Card>
          
          <Card className='my-2'>
            <CardBody style={{marginLeft:'10px', marginRight:'10px',paddingTop:'10px', paddingBottom:'10px'}}>
              <CardTitle tag="h3">Day 03</CardTitle>
              <CardTitle tag="h4">Munnar - Thekkday</CardTitle>
              <CardText style={{fontSize:'16px'}}>
                After breakfast proceed to <strong>Thekkady</strong>. 145kms from Munnar.
              </CardText>
              <CardImg 
                alt="Card image cap"
                src="/assets/img/thekkady.jpg"
                style={{
                    height: "100%"
                }}
                top
                width="100%" rounded/>
              <CardText style={{fontSize:'16px'}}>
                Thekkady which offers diverse attractions like Periyar Wildlife Sanctuary, Hill Station
                and Spice Plantation. Here you can go for a Wildlife cruise to view a large variety of
                wildlife like Wild Elephants, Nilgiri Langur, Sambar Deer, Wild Bison and quite a lot
                of unique birds can also be spotted. You can recreate yourself by indulging in
                optional (chargeable basis) activities like Spice Plantation Tour, Bamboo Rafting,
                Trekking and Elephant ride.
              </CardText>
              <CardText style={{fontSize:'16px'}}>
                Overnight stay at the Hotel/Resort.
              </CardText>
            </CardBody>                        
          </Card>

          <Card className='my-2'>                        
            <CardBody style={{marginLeft:'10px', marginRight:'10px',paddingTop:'10px', paddingBottom:'10px'}}>            
              <CardTitle tag="h3">Day 04</CardTitle>
              <CardTitle tag="h4">Thekkday – Madurai – Rameswaram</CardTitle>
              <CardText style={{fontSize:'16px'}}>
                After an Early breakfast proceed to <strong>Rameswaram</strong>. 320 kms
                from Thekkady.
              </CardText>
              <CardImg 
                alt="Card image cap"
                src="/assets/img/rameswaram1.jpg"
                style={{
                  height: "100%"
                }}
                top
                width="100%" rounded/>
              <CardText style={{fontSize:'16px'}}>
                Onthe way visit <strong>Meenakshi Amman Temple,Gandhi Meuseum</strong>.
              </CardText>
              <CardText style={{fontSize:'16px'}}> On
                arrival at Rameswaram check in the Hotel and Fresh up visit Ramanathaswamy
                Temple. Overnight Stay at Rameswaram Hotel.        </CardText>
            </CardBody>                        
          </Card>                         
    
          <Card className='my-2'>
            <CardBody style={{marginLeft:'10px', marginRight:'10px',paddingTop:'10px', paddingBottom:'10px'}}>
              <CardTitle tag="h3">Day 05</CardTitle>
              <CardTitle tag="h4">Rameswaram - Kanyakumari</CardTitle>
              <CardText style={{fontSize:'16px'}}>
                After breakfast proceed Rameswaram Sightseeing –, <strong>Agnitheertham,
                Dhanushkodi (Jeep cost extra), Former President Shri. Dr. Abdul Kalam’s House,
                Pamban Bridge</strong>. After proceed to Kanyakumari. 320kms from Rameswaram.
              </CardText>
              <CardImg 
                alt="Card image cap"
                src="/assets/img/Kanyakumari2.jpg"
                style={{
                    height: "100%"
                }}
                top
                width="100%" rounded/>
              <CardText style={{fontSize:'16px'}}>
                Kanyakumari is a coastal town in the state of Tamil Nadu on India&#39;s southern tip.
                Jutting into the Laccadive Sea, the town was known as Cape Comorin during British
                rule and is popular for watching <strong>sunrise and sunset</strong> over the ocean. <strong>Bhagavathy
                Amman Temple, Gandhi Memorial, Bharat Mata Temple, Kanyakumari Beach,
                Sunset View Point</strong>.
              </CardText>
              <CardText style={{fontSize:'16px'}}>
                Overnight stay at Kanyakumari hotel.
              </CardText>
            </CardBody>                        
          </Card> 
          
          <Card className='my-2'>
            <CardBody style={{marginLeft:'10px', marginRight:'10px',paddingTop:'10px', paddingBottom:'10px'}}>
              <CardTitle tag="h3">Day 06</CardTitle>
              <CardTitle tag="h4">Kanyakumari – Trivandrum</CardTitle>
              <CardText style={{fontSize:'16px'}}>
                Early Morning visit <strong>Thiruvalluvar Statue, Vivekananda Rock Memorial</strong>. After
                breakfast proceed to <strong>Kovalam</strong>.
              </CardText>
              <CardImg 
                alt="Card image cap"
                src="/assets/img/tvpm.jpg"
                style={{
                    height: "100%"
                }}
                top
                width="100%" rounded/>
              <CardText style={{fontSize:'16px'}}>
                <strong>Kovalam</strong> is known as the <strong>“Paradise of the South”</strong>.
                Visit the crescent shaped beaches of Kovalam . <strong>Samudra Beach, Hawa Beach &amp;
                Lighthouse Beach</strong>. With high rock promontory and a calm bay of blue waters, this
                beach paradise creates a unique aquarelle on moonlight nights. 
              </CardText>
              <CardText style={{fontSize:'16px'}}>
                Overnight stay at Kovalam / Trivandrum Hotel/Resort.        
              </CardText>
            </CardBody>                        
          </Card>         
   
          <Card className='my-2'>
            <CardBody style={{marginLeft:'10px', marginRight:'10px',paddingTop:'10px', paddingBottom:'10px'}}>    
              <CardTitle tag="h3">Day 07</CardTitle>
              <CardTitle tag="h4">Trivandrum – Alleppey</CardTitle>
              <CardText style={{fontSize:'16px'}}>
                After Breakfast Visit <strong>Sree Padmanabha Swami Temple, Kuthiramalika Palace
                museum, The Napier museum, Sree Chithra art gallery, zoological park, science and
                technology museum, Kanakakunnu Palace, planetarium</strong>. After proceed to Alleppey.
              </CardText>
              <CardImg 
                alt="Card image cap"
                src="/public/assets/img/alleppey2.jpg"
                style={{
                    height: "100%"
                }}
                top
                width="100%" rounded/>  
              <CardText style={{fontSize:'16px'}}>            
                <strong>Alappuzha</strong> is the Venice of the East; It is famous for its boat races, backwater
                holidays, beaches, marine products and coir industry. Alappuzha beach is a popular
                picnic spot. The pier, which extends into the sea here, is over 137 years old.
                Entertainment facilities at the Vijaya Beach Park add to the attraction of the beach.
                There is also an old lighthouse nearby which is greatly fascinating to visitors.
                Overnight stay at Alleppey.
              </CardText>
              <CardText style={{fontSize:'16px'}}>
                (Option 2: <strong>House Boat</strong> : This 21 hrs. Kerala houseboat package in Alleppey is designed for those who want to experience Alleppey backwaters in a houseboat. It is necessary to have proper Guidance and information about the Kerala Tour Packages before visiting this places.
                <br/>Experience <strong>Alleppey backwaters</strong>, one of the must see destinations during a life time, in a houseboat is an experience beyond imagination. The main attraction of Alleppey is criss crossed canals, lagoons, villages on the banks of these canals, paddy fields etc – all these makes Alleppey a much sought after destination among tourists. To see these Alleppey backwaters you need to hire any means of water transport. This program is designed to experience Alleppey backwaters with overnight stay in a private houseboat.
                <br/>During the trip in houseboat for 21 hrs. you will be provided fresh lemon as welcome drink, authentic Kerala veg and non-veg along with local rice and many vegetable dishes. Welcome drink, lunch, evening refreshments, dinner and next day breakfast are all included during your stay in houseboat and all these are prepared in the houseboat itself by exclusive chef.  According to category of the houseboat you booked from Kerala Houseboat package or Alleppey Houseboat Package, the menu may differ.
                <br/>
                <ul>
                  <li>Boarding and Deboarding place – Alleppey Punnamada jetty / Pallathuruthy Jetty</li>
                  <li>Check in  time – 12:30 hrs.</li>
                  <li>Check Out time – 09:00 hrs. Next day.</li>
                </ul>
              </CardText>
            </CardBody>                        
          </Card>  
        
          <Card className='my-2'>
            <CardBody style={{marginLeft:'10px', marginRight:'10px',paddingTop:'10px', paddingBottom:'10px'}}>    
              <CardTitle tag="h3">Day 08</CardTitle>
              <CardTitle tag="h4">Alleppey - Cochin</CardTitle>
              <CardText style={{fontSize:'16px'}}>
                After breakfast proceed to Cochin. 60kms from Alleppey.
              </CardText>
              <CardImg 
                alt="Card image cap"
                src="/assets/img/Cochin1.jpg"
                style={{
                  height: "100%"
                }}
                top
                width="100%" rounded/>    
              <CardText style={{fontSize:'16px'}}>
                Proceed for a half day city sightseeing tour of Cochin visiting <strong>Chinese Fishing Nets,
                St. Francis Church, Santa Cruz Basilica, Jewish Synagogue, and Mattancherry
                Palace/Dutch Palace</strong>. Shopping at Maraine Drive.
                Evening proceed to Drop at Ernakulam Railway station.
              </CardText>
              <CardText style={{fontSize:'16px'}}>
                Drive back to your hometown with the sweet memories of Tamilnadu/Kerala trip with
                Kairaly Holidays.
              </CardText>
            </CardBody>                        
          </Card>
        </Grid>
      </div>
    )
  }
}