import React, { Component } from 'react';
import {Image} from 'react-bootstrap';
//import { Card, CardImg, CardBody, CardTitle, CardText } from 'reactstrap';
//import Card from 'react-bootstrap/Card';
// import {Grid,Col,Image,Button, Row} from 'react-bootstrap';
import '../Styles/about.css';
import '../Styles/home.css';

export default class Madurai extends Component{
    render(){
        return(
            <div>                
                <div className="content-wrapper">    
                    <Image src="/assets/img/Madurai_Tour.png" />
                                       
                </div>
                <div className="content-wrapper">    
                    <Image src="/assets/img/Madurai_about.png" />
                                       
                </div>
            </div>
        )
    }
}