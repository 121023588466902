import React, { Component } from 'react';
import {Thumbnail,Grid,Col,Image,Button,Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import '../Styles/services.css'
export default class Home extends Component{
    render(){
        return( <div>
        <div className="content-wrapper">    
                <Image src="/assets/img/travel-banner.jpg" />
                <h2 className="heading">Our services</h2>              
                <h5 className="sub-heading">With us you’ll find a perfect destination among hundreds available.</h5>
                
            </div>
           <Grid>
            <Row className= "show-grid text-center">
                 <Col xs={12} sm={3} className="place">
                    <Thumbnail className="thumbnail_border">
                    <i className="fas fa-plane-departure fa-7x"></i>
                        <h3>TICKETS</h3>
                        <p> We can provide options for booking domestic and international flight tickets - 
                            offer different classes of tickets 'economy, business, first class' and various airlines to choose from.
                            For travelers using trains as their mode of transportation, we can help book train tickets.
                            If you're traveling by bus, particularly for long-distance or intercity journeys, 
                            we may offer bus ticket booking services.</p>
                        
                        <p>
                        <Link to="/contact">
                        <button class="comic-button">Contact</button></Link>
                        </p>
                    </Thumbnail>                   
                </Col>

                 <Col xs={12} sm={3} className="place">
                    <Thumbnail className="thumbnail_border" >
                    <i class="fas fa-ship fa-7x"></i>
                    
                        <h3>HOUSE BOATS & CRUISES</h3>
                        {/* <p> Alappuzha is a watery heaven of interlinking canals and paddy fields, amazing coconut trees. The best view of this landslip is from outside, from the Alleppey houseboats cruising through the backwaters. Houseboat travelling is one of the best experience in your life time to get that much of beautiful sightseeing and freshness of the Kerala. The cool breeze, The Sunset and sunrise and the occasional migratory birds, and seasonal rains make the boat ride a unique experience that you can enjoy the fell of Gods own country “Kerala“. Alappuzha tour package provides a lot of adventure and amazing food and sightseeing and feel like a luxury trip through Nandanam tours and travels.
                        </p> */}
                        <p>Book the perfect houseboat at Alleppey and Kumarakom at lot of offer credible houseboat service at Alleppey with taste food, awesome sight seeing in your rental houseboat in Alappuzha. We have online Houseboat booking facility  to make your destination easier with us, We provide nearest tourpackages from alappuzha To munnar tour packages, Alappuzha thekkady tour packages, Alappuzha cochin tour packages etc in budget and luxury.
                        </p>
                        {/* <p>
                        House boating can be the most relaxing experience for any kind of people from child to older. All our house boat are feel like 3 star property and all the facilities available in the houseboat itself like dinning hall, bed rooms with ac and no ac, kitchen balcony etc. we provide 2 bed room house boat to 6 bed room house boat can occupy more than 25 peoples in a single house boat.
                        </p> */}
                        {/* <p>
                        One of the best honeymoon destination in Kerala is the house boat packages in alappuzha and kumarkom houseboat packages. we preferred one night stay in Alappuzha and one night stay in munnar for 2 days tour package.
                        </p> */}
                        <p>
                        <Link to="/contact">
                        <button class="comic-button">Contact</button></Link>
                        </p>
                    </Thumbnail>                   
                </Col>

                 <Col xs={12} sm={3} className="place">
                    <Thumbnail className="thumbnail_border">
                    <i class="fas fa-hotel fa-7x"></i>
                        <h3>HOTEL RESERVATIONS</h3>
                        <p> Build your own low-cost holidays with the best hotel deals and combinations. 
                            We can provide information on different types of accommodations, including hotels,
                             resorts, hostels, and vacation rentals. Have it your way!</p>
                         <p>
                         <Link to="/contact">
                         <button class="comic-button">Contact</button></Link>
                        </p>
                    </Thumbnail>                   
                </Col>

                 <Col xs={12} sm={3} className="place">
                    <Thumbnail className="thumbnail_border">
                        <i class="fas fa-taxi fa-7x"></i>
                        <h3>TAXI SERVICES</h3>
                         <p> We have experienced chauffeurs and well maintained vehicles. All our staffs are well trained in the hospitality over the years and passionate about their work. We know how important role the drivers play in our service so we provide the best driver who speaks Hindi, English and many other languages. We offer a complete range of travel related services in South India.</p>
                        <p>
                        <Link to="/contact">
                        <button class="comic-button">Contact</button></Link>
                        </p>
                    </Thumbnail>                   
                </Col>

                   

                   
                </Row>
                   </Grid>
                 
                   </div>
            )}}