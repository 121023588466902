import React, { Component } from 'react';
import {Image} from "react-bootstrap";
//import { Card, CardImg, CardBody, CardTitle, CardText } from 'reactstrap';
//import Card from 'react-bootstrap/Card';
// import {Grid,Col,Image,Button, Row} from 'react-bootstrap';
import '../Styles/about.css';
import '../Styles/home.css';
import '../Styles/packages.css';

export default class Packages extends Component{
  render(){
    return(
      <div>
        <div className="content-wrapper">    
          <Image src="/assets/img/honey.jpg" />
          <h2 className="heading">Our Packages</h2>         
        </div>
        <div className="accordion">
          <span class="target-fix" id="accordion"></span>
          <div>
            <span class="target-fix" id="accordion1"></span>
            <a href="#accordion1" id="open-accordion1" title="open">Trivandrum - Kovalam - Poovar - Kanyakumari - Munnar - Cochin</a>
            <a href="#accordion" id="close-accordion1" title="close">Trivandrum - Kovalam - Poovar - Kanyakumari - Munnar - Cochin</a> 
            <div class="accordion-content">
              <p>
                <h3>Day 01 Trivandrum - Kovalam</h3>
                On arrival at Trivandrum Airport or Railway station our representative will meet and
                drive you to <strong>Kovalam</strong>. Kovalam is known as the “Paradise of the South”. Visit the
                crescent shaped beaches of Kovalam i.e. Samudra Beach, Hawa Beach &amp;
                Lighthouse Beach. With high rock promontory and a calm bay of blue waters, this
                beach paradise creates a unique aquarelle on moonlight nights. Overnight stay at
                Kovalam Resort.
              </p>
              <p>
                <h3>Day 02 : Kovalam – Poovar – Kovalam</h3>
                After Break fast Proceed to poovar Beach. A stunning beach with golden sand and
                gentle breeze nestled between the gorgeous River Neyyar and Arabian Sea. The
                swaying coconut palms add to the beauty of this picture perfect beach which is only
                accessible via a water taxi alone. Overnight stay at Kovalam Resort.
              </p>
              <p>
                <h3>Day 03 : Kovalam – Trivandrum – Kovalam</h3>
                After breakfast, proceed toTrivandrum . Sri Padmanabhaswamy temple is the most
                religious icon of the city. Other sightseeing places are Trivandrum Zoo, Sri chitra Art
                Gallery, Veli Tourist Village Etc. Overnight stay at Kovalam Resort.
              </p>
              <p>
                <h3>Day 04 : Kovalam – Kanyakumari</h3>
                After breakfast drive you to Kanyakumari, 90 Kilometers from Trivandrum.
                Kanyakumari is a coastal town in the state of Tamil Nadu on India&#39;s southern tip.
                Jutting into the Laccadive Sea, the town was known as Cape Comorin during British
                rule and is popular for watching sunrise and sunset over the ocean. Visit
                Thiruvalluvar Statue, Vivekananda Rock Memorial, Bhagavathy Amman Temple,
                Gandhi Memorial, Bharat Mata Temple, Kanyakumari Beach, Sunset View Point.
                Overnight stay at Kanyakumari hotel.
              </p>
              <p>
                <h3>Day 05 : Kanyakumari – Munnar</h3>
                After breakfast drive you to Munnar. 370kms from Kanyakumari .
                The Idyllic tourist destination in God’s own country. Munnar is a nature lovers
                paradise with endless Tea gardens lakes on western ghat hills , basically it is a tea
                town. Overnight stay at Munnar hotel.
              </p>
              <p>
                <h3>Day 06 Munnar</h3>
                After breakfast proceed for the sightseeing of Munnar covering Mattupetty Dam,
                Echo Point, Kundale Lake, Blossom Garden.In Mattupetty you can enjoy Elephant
                Safari, Horse Riding, Speed Boat. (own cost).In Kundala Dam you can enjoy Pedal
                Boating. Photo stops at Tea Gardens &amp; Rose Gardens.Overnight stay at
                Hotel/Resort.
              </p>
              <p>
                <h3>Day 07 Munnar</h3>
                After breakfast proceed to morning sightseeing Rajamalai Hills offering a
                spectacular view, Eravikulam Wildlife sanctuary, home of Nilgiri Thar( very rare
                mountain goat), considering the ecological faunal, floral, geo-morphological and
                zoological significant &amp; Tea Plantation.Later Visit Tea Museum &amp; Shopping at
                Munnar Town. Evening visit Kathakali Show (own cost).Overnight stay at Munnar
                Hotel/Resort.
              </p>
              <p>
                <h3>Day 8 Munnar – Cochin</h3>
                After breakfast proceed to Drop at Cochin Airport or Railway station.
                If possible Sightseeing.Drive back to your hometown with the sweet memories of
                Kerala trip with Kairaly Holidays.
              </p>
            </div>
          </div>          
                  
          <div>
            <span class="target-fix" id="accordion2"></span>
            <a href="#accordion2" id="open-accordion2" title="open">1N Kanyakumari - 1N Kovalam - 1N Alleppey - 1N Thekkady - 2N Munnar</a>
            <a href="#accordion" id="close-accordion2" title="close">1N Kanyakumari - 1N Kovalam - 1N Alleppey - 1N Thekkady - 2N Munnar</a>
            <div class="accordion-content">
              <p>
                <h3>Day 01: Trivandrum - Kanyakumari</h3>
                On arrival at Trivandrum Airport or Railway station our representative will meet and
                drive you to Kanyakumari, 90 Kilometers from Trivandrum.
                Kanyakumari is a coastal town in the state of Tamil Nadu on India&#39;s southern tip.
                Jutting into the Laccadive Sea, the town was known as Cape Comorin during British
                rule and is popular for watching sunrise and sunset over the ocean. Visit
                Padmanabhapuram Palace, Thiruvalluvar Statue, Vivekananda Rock Memorial,
                Bhagavathy Amman Temple, Gandhi Memorial, Bharat Mata Temple, Kanyakumari
                Beach, Sunset View Point.
                Overnight stay at hotel.
              </p>
              <p>
                <h3>Day 02: Kanyakumari – Kovalam - Trivandrum</h3>
                After breakfast, check-out and proceed to Kovalam beach. Sri Padmanabhaswamy
                temple is the most religious icon of the city. Other sightseeing places are Trivandrum
                Zoo, Sri chitra Art Gallery, Veli Tourist Village.Kovalam is known as the “Paradise of
                the South”. Visit the crescent shaped beaches of Kovalam i.e. Samudra Beach,
                Hawa Beach &amp; Lighthouse Beach. With high rock promontory and a calm bay of
                blue waters, this beach paradise creates a unique aquarelle on moonlight nights.
                Overnight stay at Kovalam Hotel/Resort.
              </p>
              <p>
                <h3>Day 03: Kovalam - Alleppey</h3>
                After breakfast proceed to Alleppey. 150kms from Kovalam.
                Alleppey is the Venice of the East; It is famous for its boat races, backwater
                holidays, beaches, marine products and coir industry. Alappuzha beach is a popular
                picnic spot. The pier, which extends into the sea here, is over 137 years old.
                Entertainment facilities at the Vijaya Beach Park add to the attraction of the beach.
                There is also an old lighthouse nearby which is greatly fascinating to visitors.
                Evening leisure at backwater resort. Overnight stay at Alleppey.
                (Option 2: House Boat : This 21 hrs. Kerala houseboat package in Alleppey is
                designed for those who want to experience Alleppey backwaters in a houseboat. It is

                necessary to have proper Guidance and information about the Kerala Tour
                Packages before visiting this places.
                Experience Alleppey backwaters, one of the must see destinations during a life time,
                in a houseboat is an experience beyond imagination. The main attraction of Alleppey
                is criss crossed canals, lagoons, villages on the banks of these canals, paddy fields
                etc – all these makes Alleppey a much sought after destination among tourists. To
                see these Alleppey backwaters you need to hire any means of water transport. This
                program is designed to experience Alleppey backwaters with overnight stay in a
                private houseboat.
                During the trip in houseboat for 21 hrs. you will be provided fresh lemon as welcome
                drink, authentic Kerala veg and non-veg along with local rice and many vegetable
                dishes. Welcome drink, lunch, evening refreshments, dinner and next day breakfast
                are all included during your stay in houseboat and all these are prepared in the
                houseboat itself by exclusive chef.  According to category of the houseboat you
                booked from Kerala Houseboat package or Alleppey Houseboat Package, the menu
                may differ.
                 Boarding and Deboarding place – Alleppey Punnamada jetty / Pallathuruthy
                Jetty
                 Check in time – 12:30 hrs.
                 Check Out time – 09:00 hrs. Next day.)
              </p>
              <p>
                <h3>Day 04: Alleppey - Thekkady</h3>
                After breakfast proceed to Thekkady. 140kms from Thekkady 
                Which offers diverse attractions like Periyar Wildlife Sanctuary, Hill Station and
                Spice Plantation. Here you can go for a Wildlife cruise to view a large variety of
                wildlife like Wild Elephants, Nilgiri Langur, Sambar Deer, Wild Bison and quite a lot
                of unique birds can also be spotted. You can recreate yourself by indulging in
                optional (chargeable basis) activities like Spice Plantation Tour, Bamboo Rafting,
                Trekking and Elephant ride. 
                Overnight stay at the Hotel/Resort.
              </p>
              <p>
                <h3>Day 05: Thekkady - Munnar</h3>
                After breakfast proceed to Munnar. 90kms from Thekkady.

                The Idyllic tourist destination in God’s own country. Munnar is a nature lovers
                paradise with endless Tea gardens lakes on western ghat hills , basically it is a tea
                town. After breakfast proceed for the sightseeing of Munnar covering Mattupetty
                Dam, Echo Point, Kundale Lake, Blossom Garden.In Mattupetty you can enjoy
                Elephant Safari, Horse Riding, Speed Boat. (own cost).In Kundala Dam you can
                enjoy Pedal Boating. Photo stops at Tea Gardens &amp; Rose Gardens.
                Overnight stay at Hotel/Resort.
              </p>
              <p>
                <h3>Day 06: Munnar</h3>
                After breakfast proceed to morning sightseeing Rajamalai Hills offering a
                spectacular view, Eravikulam Wildlife sanctuary, home of Nilgiri Thar( very rare
                mountain goat), considering the ecological faunal, floral, geo-morphological and
                zoological significant &amp; Tea Plantation.Later Visit Tea Museum &amp; Shopping at
                Munnar Town. Evening visit Kathakali Show (own cost).
                Overnight stay at Munnar Hotel/Resort.
              </p>
              <p>
                <h3>Day 07: Munnar – Cochin</h3>
                After breakfast proceed to Drop at Cochin Airport or Railway station.
                If possible Sightseeing.
                Drive back to your hometown with the sweet memories of Kerala trip with Kairaly
                Holidays.
              </p>       
            </div>
          </div>
                        
          <div>
            <span class="target-fix" id="accordion3"></span>
            <a href="#accordion3" id="open-accordion3" title="open">Bangalore - Wayanad - Bangalore _adventure</a>
            <a href="#accordion" id="close-accordion3" title="close">Bangalore - Wayanad - Bangalore _adventure</a>
            <div class="accordion-content">
              <p>
                <h3>Day 01: Wayanad</h3>
                Arrived at Wayanad and proceed trek to <strong>Thusharagiri Waterfalls</strong>. 
                Two streams originating from the Western Ghats meet here to form the <strong>Chalippuzha River</strong>. 
                The river diverges into three waterfalls creating a snowy spray, which gives the name, 'Thusharagiri'. 
                Thusharagiri offers endless scope for trekking, rock climbing and wild life sanctuary visits. 
                Afternoon Visit <strong>Banasura Dam</strong>. Considered to be the largest earth dam in India. 
                Proceed back to your hotel/Homestay for the overnight stay. Overnight at the Wayanad,Proceed back to your hotel/Homestay for the overnight stay.Overnight at the Wayanad
              </p> 
              <p>
                <h3>Day 02: Wayanad</h3>
                After breakfast trek to <strong>Chembra peak</strong> with a packed lunch. At 2100 metres, 
                the spectacular Chembra Peak located towards the southern part of Wayanad is the tallest summit in the region. 
                Climbing this peak is a challenging mountaineering endeavour and would take a full day. 
                If time permits afternoon Visit <strong>Soochippara waterfalls</strong>. Proceed back to your hotel for the overnight stay.Overnight at Wayanad.
              </p>
              <p>
                <h3>Day 03: Wayanad</h3>
                Before Breakfast Jungle safari to <strong>Muthanga wild Life sanctuary</strong>. it is a rain foreWayanad 
                Homestaysst reserve connected to two other major sanctuaries: <strong>Bandipur National Park</strong> in Karnataka
                and <strong>Mudumalai Sanctuary</strong> in Tamil Nadu. Afternoon trail to <strong>Kuruva island</strong>, 
                The Kuruva Island with 950 acres of evergreen forest lies on one of the tributaries of the Kabini. 
                The island is home to various species of rare birds, orchids and herbs.	Evening Depart to Bangalore at 4PM.
              </p>
            </div>
          </div>

          <div>
            <span class="target-fix" id="accordion4"></span>
            <a href="#accordion4" id="open-accordion4" title="open">Trivandrum – Kovalam – Varkala – Alleppey – Kumarakom – Thekkady- Munnar - Cochin - 9 Nights 10 Days</a>
            <a href="#accordion" id="close-accordion4" title="close">Trivandrum – Kovalam – Varkala – Alleppey – Kumarakom – Thekkady- Munnar - Cochin - 9 Nights 10 Days</a>
            <div class="accordion-content">
              <p>
                <h3>Day 01: Trivandrum – Kovalam</h3>
                On arrival at Trivandrum Airport or Railway station our representative will meet and drive you to <strong>Kovalam</strong>.
                15kms from Trivandrum. Kovalam is known as the <strong>“Paradise of the South”</strong>. 
                Visit the crescent shaped beaches of Kovalam i.e. Samudra Beach, Hawa Beach & Lighthouse Beach.
                 With high rock promontory and a calm bay of blue waters, this beach paradise creates a unique aquarelle on moonlight nights.
                 Overnight stay at Kovalam Hotel/Resort.
              </p> 
              <p>
                <h3>Day 02: Kovalam - Trivandrum - Kovalam</h3>
                After breakfast proceed to Trivandrum. Visit <strong>Sree Padmanabha Swami Temple, Kuthiramalika Palace museum, 
                The Napier museum, Sree Chithra art gallery, zoological park, science and technology museum, Kanakakunnu Palace, 
                Planetarium</strong>.Overnight stay at the Hotel/Resort in Kovalam
              </p>
              <p>
                <h3>Day 03: Kovalam - Varkala</h3>
                After breakfast proceed to Varkala. 60kms from Kovalam.
                <strong>Varkala</strong> is an ideal place to relax…or one can say, it is meant only for relaxing!
                <strong>Varkala Beach, Sivagiri Mutt, Thiruvambadi Beach, Edava Beach, Janardana Swamy Temple, Vishnu Temple, Anjengo Fort,Water Sports in Varkala</strong>.
                 Overnight stay at hotel.
              </p>
              <p>
                <h3>Day 04: Varkala - Alleppey</h3>
                After breakfast proceed to Alleppey. 120kms from Varkala. <strong>Alappuzha</strong> is the Venice of the East; 
                It is famous for its boat races, backwater holidays, beaches, marine products and coir industry. 
                Alappuzha beach is a popular picnic spot. The pier, which extends into the sea here, is over 137 years old. 
                There is also an old lighthouse nearby which is greatly fascinating to visitors. Evening leisure at backwater resort.
                 Overnight stay at Alleppey. 
              </p>
              <p>
                <h3>Day 05: Alleppey - Kumarakom</h3>
                After breakfast proceed to <strong>Kumarakom</strong>. 40kms from Alleppey. The major attraction is the 
                <strong>Kumarakom Bird Sanctuary</strong> where a profusion of all kinds of birds can be observed such as 
                Siberian storks, cuckoo, wild duck, egrets, darters, herons, teal and water fowl. 
                Overnight stay at Kumarakom Hotel/Resort
              </p>
              <p>
                <h3>Day 06: Kumarakom - Thekkady</h3>
                After breakfast proceed to <strong>Thekkady</strong>. 120kms from Kumarakom. Thekkady which offers diverse attractions 
                like <strong>Periyar Wildlife Sanctuary, Hill Station and Spice Plantation</strong>. Here you can go for a 
                Wildlife cruise to view a large variety of wildlife like Wild Elephants, Nilgiri Langur, Sambar Deer, Wild Bison 
                and quite a lot of unique birds can also be spotted. You can recreate yourself by indulging in optional 
                (chargeable basis) activities like Spice Plantation Tour, Bamboo Rafting, Trekking and Elephant ride. Overnight stay at the Hotel/Resort.
              </p>
              <p>
                <h3>Day 07: Thekkady - Munnar</h3>
                After breakfast proceed to <strong>Munnar</strong>. 120 kms from Thekkady. The Idyllic tourist destination in 
                God’s own country. Munnar is a nature lovers paradise with endless Tea gardens lakes on western ghat hills , 
                basically it is a tea town. After breakfast proceed for the sightseeing of Munnar covering <strong>Mattupetty Dam, 
                Echo Point, Kundale Lake, Blossom Garden</strong>.In Mattupetty you can enjoy <strong>Elephant Safari, Horse Riding, 
                Speed Boat</strong>(own cost). In Kundala Dam you can enjoy Pedal Boating. Photo stops at Tea Gardens & Rose Gardens.
                Overnight stay at Hotel/Resort.
              </p>
              <p>
                <h3>Day 08: Munnar</h3>
                After breakfast proceed to morning sightseeing <strong>Rajamalai Hills</strong> offering a spectacular view, 
                <strong>Eravikulam Wildlife sanctuary</strong>, home of Nilgiri Thar( very rare mountain goat), 
                considering the ecological faunal, floral, geo-morphological and zoological significant & Tea Plantation.
                Later Visit <strong>Tea Museum</strong> & Shopping at Munnar Town. Evening visit <strong>Kathakali Show</strong> (own cost). 
                Overnight stay at Munnar Hotel/Resort.
              </p>
              <p>
                <h3>Day 09: Munnar - Cochin</h3>
                After breakfast proceed to <strong>Cochin</strong>. 140kms from Munnar. Afternoon proceed for a half day city 
                sightseeing tour of Cochin visiting <strong>Chinese Fishing Nets, St. Francis Church, Santa Cruz Basilica, 
                Jewish Synagogue, and Mattancherry Palace/Dutch Palace</strong>. Overnight stay at Cochin Hotel/Resort.
              </p>
              <p>
                <h3>Day 10: Cochin Drop</h3>
                After breakfast proceed to Drop at Trivandrum/Cochin Airport or Railway station. If possible Sightseeing.
                Drive back to your hometown with the sweet memories of Kerala trip with Kairaly
              </p>
            </div>
          </div>
        </div> 
      </div>         
    )
  }
}